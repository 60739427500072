body {
    box-sizing: content-box;
}

.grid_content {
    background-color: #6d6d70;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    width: 100%;
}

.container_form {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    background-image: url('../img/fondo.png');
    background: -webkit-linear-gradient(90deg, #aecee7,#76acd0,#65a3cc);
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.form {
    background-color: #f5f5f9;
    box-sizing: border-box;
    padding: 2rem 2rem 3rem 2rem;
    height: auto;
    max-width: 1200px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: 1px 1px 10px 1px #e7e7e7;
    border-radius: 10px;
    gap: .2rem;
}

@media (max-width: 600px){
    .form {
        margin: 0 1.5rem;
    }

    .form {
        max-width: 350px;
        height: auto;
    }

    .content_inputs {
        flex-direction: column;
        align-items: stretch !important;
        gap: .2rem !important;
    }

    .form > h1 {
        font-size: 1.7rem;
    }
}

.form > h1 {
    color: #4B97C5;
    margin-bottom: 0rem;
}
.form > p {
    margin-bottom: 2rem;
}

.content_inputs {
    align-items: center;
    display: flex;
    gap: .8rem;
    width: 100%;
    min-width: 40vw;
}

.input {
    outline: none;
    border: 1px solid #e7e7e7;
    padding: .5rem;
    border-radius: 5px;
    flex-grow: 1;
    transition: 1s;
}

.input:focus {
    border: 1px solid #c6c6d6;
    transition: 1s;
    box-shadow: 1px 1px 10px 1px #d4d4e0;
    }
    
    #archivoInput {
    display: none;
    }
    
    .archivoLabel {
    background-color: #4e4e4d;
    color: #fff;
    padding: 7px 15px;
    border-radius: 5px;
    cursor: pointer;
    font-size: .8rem;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    }
    
    .archivoLabel:hover {
    background-color: #a0a0c9;
    opacity: .9;
    }
    
    .archivoCargado {
    background-color: #76acd0;
    }
    
    .archivoInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    }
    
    .btnEliminarArchivo {
    background: none;
    border: none;
    color: red;
    cursor: pointer;
    font-size: 1rem;
    }
    
    .file {
    margin-top: .5rem;
    display: flex;
    width: 100%;
    }
    
    .file_send {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: .5rem;
    }
    
    .boton {
    margin-top: .5rem;
    }
    
    .send_btn {
    border: none;
    outline: none;
    padding: 6px 2rem;
    background-color: #4b97c5;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
    font-size: .9rem;
    }
    
    .border {
    margin: .8rem 0 .4rem 0;
    width: 100%;
    border-bottom: 1px solid #d4d4e0;
    }
    
    /* LOADING */
    
    #loadingIndicator {
    display:none;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    }
    
    .content_loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 2em;
    }
    
    @keyframes spin {
    from {
    transform: rotate(0deg);
    }
    to {
    transform: rotate(360deg);
    }
    }
    
    #loader {
    animation: spin 2s linear infinite;
    }