
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;800;900&family=Sigmar&display=swap');

:root {
    --font-montserrat: 'Montserrat';
}

* {
    font-family: var(--font-montserrat);
}

body {
    background-color: #fff !important; 
}

.container_grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 100px 1fr;
    height: 100vh;
    width: 100%;
}

/* NAV */

.logo_btnadmin {
    grid-column: 1 / 2;
    grid-row: 1 / 2;

    display: flex;
    align-items: center;
    box-sizing: border-box;
}

.logo_post {
    position: absolute;
    top: 1rem;
    left: 1rem;
}

.logo {
    background-image: url('../img/logo.png');
    background-size: cover;
    font-size: 3.5rem;
    padding: 0 8.5rem;
    margin: 2rem 0 0 1.5rem;
}

.boton_admin {
    background-color: #359600;
    color: #fff;
    outline: none;
    border: none;
    cursor: pointer;
    border-radius: 10px;
    padding: 10px 1.5rem;
    right: 2.5rem;
}

.tickets_logo {
    /* grid-column: 1 / 2;
    grid-row: 2 / 3; */

    display: contents;
    /* grid-template-columns: 498px 1fr;
    grid-template-rows: 1fr; */
}

.text_tickets {
    grid-column: 1 / 2;
    grid-row: 1 / 2;

    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0;
    box-sizing: border-box;
}

.icono_repr {
    grid-column: 2 / 3;
    grid-row: 1 / 2;

    background-image: url('../img/icono_tickets.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    height: 45rem;
    /* height: 450px; */
    margin-top: 2rem;
}

@media (max-width: 600px){
    .icono_repr {
        display: none;
    }

    .text_tickets {
        grid-row: 1 / 3;
    }

    .tickets_logo {
        display: grid;
        grid-template-columns: 100% 1fr;
    }

    .title_tickets {
        font-size: 1.5rem;
        padding: 0 2.5rem;
        box-sizing: border-box;
        text-align: left !important;
    }

    .boton_register {
        margin: auto auto auto -.9rem;
    } 

    .boton_admin {
        right: 1rem;
    }
    
    .logo_post {
        left: 0;
        top: .5rem;
    }
}

.slogans_around > p {
    width: 79%;
    margin: auto;
}

.title_tickets {
    color: #5177fe;
    font-size: 2.6rem;
    font-weight: 600;
    margin-bottom: .5rem;
    text-align: center;
}

.button_register_tickets {
    padding: 0 0 0 3.4rem;
    box-sizing: border-box;
}

.boton_register {
    outline: none;
    border: none;
    padding: 20px 1rem;
    border-radius: 15px;
    margin-top: 1.5rem;
    background-color: #4B97C5;
    color: #fff;
    cursor: pointer;
} 

.boton_register.loading {
    background-size: 200% auto;
    background-image: linear-gradient(90deg, #4B97C5, #8397ec);
    animation: loading 1.5s ease infinite;
}

@keyframes loading {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: -200% 0;
    }
}

/* MODAL ADMIN */

.admin_close {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.boton_close {
    border: 1px solid #e00d0d;
    background-color: #d88585;
    color: #fee;
    border-radius: 5px;
    padding: .2rem .5rem;
    transition: 1s;
}

.boton_close:hover {
    background-color: #e00d0d;
    transition: 1s;
}

.input_password {
    border: 1px solid #e9e9e9;
    border-radius: 5px;
    outline: none;
    padding: .2rem 0;
}

.input_password:focus {
    border: 1px solid #b7bdd8;
}

.boton_start_admin {
    outline: none;
    border: none;
    padding: .2rem;
    background-color: #5177fe;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
}

.boton_start_admin:hover {
    opacity: .9;
}